import React from 'react'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import HelmetContent from '../components/HelmetContent'

import jointInjections from '../assets/images/joint-injections.jpg'
import arthritis from '../assets/images/arthritis.jpg'
import sportsInjuries from '../assets/images/sports-injuries.jpg'
import chronicPain from '../assets/images/chronic-pain.jpg'
import cosmeticInjections from '../assets/images/cosmetic-injections.jpg'
import hairloss from '../assets/images/hairloss.jpg'

const Landing = () => (
  <Layout>
    <HelmetContent title='BMT Rejuvenation' />
    <BannerLanding heading='Bioactive Molecular Technology' content='Below you can find out more on how this amazing technology can help you' />

    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <div className="image">
            <img src={cosmeticInjections} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Cosmetics</h3>
              </header>
              <p>Bio-active Molecular technology is also used in cosmetics:
                By injecting into the dermis layer and the subcutaneous layer of the skin,
                BMT rejuvenates and repairs aging skin by helping it to return skin to its
                previous youthful look by rebuilding collagen, fat pads, and slowing down
                reduction of facial bone structure.<br />
                With aging you deplete the fat pads and bone density within your face.
                This procedure can be performed on neck and hands as well.<br />
                You have a much longer rejuvenation from BMT then Botox or fillers as these
                toxins only last for up to 5 months, BMT last at least four times longer
                depending on the client’s fitness level.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={jointInjections} alt="joint injections" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Joint and soft tissue injections</h3>
              </header>
              <p>Bio-active molecular cells rejuvenate and transform into many
                different tissues such as bone, muscle, skin, cartilage or any form of tissue.<br />
                Commonly used in joints, soft tissues, and repair of sports injuries and
                osteoarthritis. Extensive research and development on BMT has resulted in practical
                  clinical treatments being provided within a respectable time frame.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={arthritis} style={{ height: '100%' }} alt="arthritis" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Arthritis</h3>
              </header>
              <p>Osteoarthritis is characterised by damage in the cartilage and joint
                tissue that leads to bone grinding on bone. This results in mild to severe
                pain, exacerbated by inflammation in the joints that could get worse over
                time if arthritis and osteoarthritis treatment is not sought. Conventional
                methods of osteoarthritis treatment merely act to block pain or to slow
                tissue deterioration. The existing damage to the joint is not addressed.<br />
                With BMT therapy osteoarthritis treatment is possible as cartilage and joint tissue can
                be regenerated through direct injection into the target joint. BMT can regenerate any lost
                tissue, restoring the layer of cushioning between the joints that prevent grinding.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={sportsInjuries} style={{ height: '100%' }} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sports injuries</h3>
              </header>
              <p>Sports injuries are often unforeseen, and may lead to major inconveniences
                due to tears, ruptures, or other types of tissue damage. Though sports injuries
                may involve areas of the body such as muscles or cartilage, some cases involve
                damage to the joints similar to osteoarthritis. Once afflicted, sports injuries
                may cause a variety of inconveniences. Doctors often recommend rest and
                rehabilitation as sport injury treatment for milder cases, but severe sports
                injuries may require surgery.<br />
                Rehabilitation allows the body’s natural healing mechanism to repair injuries. With BMT sport
                injury treatment the healing process can be hastened which allows months of convalescent time to
                be reduced drastically. Bioactive Molecular Technology treatments allows you to treat your sports
                injuries quickly and naturally.</p>
            </div>
          </div>
        </section>
        <section>
          <div to="/generic" className="image">
            <img src={chronicPain} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Chronic Pain</h3>
              </header>
              <p>Chronic pain is characterised by pain that has lasted for longer than 6 months.
                This pain may be caused by injury or lesions putting pressure on a nerve,
                though some forms of chronic pain may persist even after an injury has healed.
                Chronic pain management may be the hardest to achieve with conventional medicine,
                as the underlying mechanics that cause pain are difficult to identify.<br />
                Bioactive Molecular Technology is able to elicit chronic pain relief effects in patients,
                though this may be an effect of the cell therapy correcting the underlying disease
                process itself. The anti-inflammatory effects of BMT such as leukocyte stem cells,
                coupled with their regenerative effects could help with chronic pain management by
                addressing the pain pathway.</p>
            </div>
          </div>
        </section>
        <section>
          <div to="/generic" className="image">
            <img src={hairloss} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Hair Loss Treatment</h3>
              </header>
              <p>The use of BMT is a great treatment option for hair loss due to its numerous
                scientifically based trials. These trials showed efficiency in increasing hair count, hair
                thickness, and the growth phase of the hair cycle. This treatment has a high
                growth factor, while also having a minimal down time associated.</p>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout >
)

export default Landing